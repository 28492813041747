import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "@assets/images/tn_logo.png";
import moment from "moment";
import { Divider, Spin } from "antd";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { LoadingButton } from "@mui/lab";
import { retrieveItem } from "@helpers/storage";
import ReactHtmlParser from "html-react-parser";
import lodash, { capitalize } from "lodash";
import {
  dynamicRequest,
  get_one_application_query,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { place_of_stay_destination } from "@helpers/constants";
function Pass({ id }) {
  const dispatch = useDispatch();
  const [loading, set_loading] = useState(false);
  let terms = retrieveItem("general_settings")?.pass_terms;
  const data = useDynamicSelector("get_application");
  // let data = {
  //   id: "12345678",
  //   name: "Someshkumar G",
  //   pass_no: "EP/N/LR/0705/1234567",
  //   mobile: "7708950755",
  //   vehicle_type: "Car",
  //   total_travelers: 4,
  //   purpose_of_visit: "Local resident",
  //   address: {
  //     line1: "109 B2, Vaigai Street, Thiruvalluvar Nagar, Vickramasingapuram",
  //     line2: "",
  //     district: {
  //       name: "Nilgiris",
  //       code: "TVL",
  //     },
  //     state: {
  //       name: "Tamil Nadu",
  //       code: "TN",
  //     },
  //     country: {
  //       name: "India",
  //     },
  //     pincode: "643211",
  //   },
  //   valid_from: "07-06-2024",
  //   valid_to: "30-06-2024",
  //   issued_by: "District Collector, Nilgiris",
  //   issued_on: "04-06-2024",
  //   district_name: "Chittoor",
  //   state_name: "",
  //   to_district: {
  //     name: "Nilgiris",
  //     code: "NGL",
  //   },
  //   vehicle_no: "TN36AD5802",
  //   qr_value:
  //     "U2FsdGVkX190dgZ2ST7G71Ct7Ycb5yCv8PLC6HwEIrw0uxYQRfiRzFUVwPA44KZYhk+ceHgs3ZaHNWDnAZ98jcPNdkMbBqOnue5img5Q/lu2btzxeCiNdYRQfWHPw2rqACLqh5KSOr65oZUBK9+ZgFNHwdqQDaHMftYY6+1Fy9CqaSCnXSmkYf1HJKryv1KBPsyx89iYi/JNCzlSILUN/D4ODerC42DewNHqDU2ftmk9Cy09359q4X5W2q307h1a1yiHP27nf0eoGV6xcHLj0DYH50PP00SdAXfFnfDUTkr7nzNxadY8oiZlkoJbquZ8V14a4a8p+ivAqMvCTLPg2mxdYPd3gbPTB7jXhLuBrXK0hlRfG+mM+LdziZEp2jzl",
  //   fuel_type: "Petrol",
  //   manufacture_year: "2024",
  //   // color: "#0D47A1",
  //   // color: "purple",
  //   color: "darkgreen",
  //   // pass_type:"commercial",
  //   pass_type: "local",
  //   // pass_type: "commercial",
  // };
  const [deviceType, setDeviceType] = React.useState("desktop");

  React.useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);
  const color = {
    T: "purple",
    B: "purple",
    FAM: "purple",
    LR: "darkgreen",
    EC: "#0D47A1",
    NEC: "#0D47A1",
    AGRI: "#0D47A1",
  };

  useEffect(() => {
    get_application();
  }, [id]);
  const get_application = () => {
    let key = [{ key: "get_application", loading: true }];
    let query = get_one_application_query;
    let variables = { id };
    dispatch(dynamicRequest(key, query, variables, "Q"));
  };

  const savePDF = async () => {
    set_loading(true);

    var options = {
      scrollX: 0,
      scrollY: 0,
      width: 793,
      height: 1122,
    };
    // if (this.props.match) {
    //   options.scrollX = -7;
    // }
    window.scrollTo(0, 0);
    const pdf = new jsPDF("p", "mm", "a4", true);
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var dataURL = "";
    var PassDesktop = document.getElementById("pass");
    if (PassDesktop) {
      PassDesktop.style.display = "block";
    }
    const printArea = document.getElementById("pass");
    await html2canvas(printArea, options).then((canvas) => {
      dataURL = canvas.toDataURL();
    });
    pdf.addImage(dataURL, "JPEG", 0, 0, width, height, "", "FAST");

    var pagePromises = [];

    // Promise.all(pagePromises).then((values) => {
    pdf.save("pass.pdf");
    // });
    if (PassDesktop) {
      PassDesktop.style.display = "none";
    }
    setTimeout(() => {
      set_loading(false);
      navigate(ROUTES.DASHBOARD);
    }, 100);
  };
  let from_code = data?.from_district?.code || data?.from_state?.code || "F";
  let to_code = data?.destination?.code;
  let address = data?.from_address_1 || "";
  let destination_type = data?.destination_type || "";
  let destination_address = data?.destination_address || "";
  let destination_name = data?.destination_name || "";
  if (data?.from_address_2) {
    address += ", " + data?.from_address_2;
  }
  if (data?.from_district?.name) address += ", " + data?.from_district?.name;
  else if (data?.from_district_name) address += ", " + data?.from_district_name;
  if (data?.from_state?.name) address += ", " + data?.from_state?.name;
  else if (data?.from_state_name) address += ", " + data?.from_state_name;
  address += ", " + (data?.from_country?.name || " ");
  address += " - " + (data?.from_pincode || " ");

  const render_place_of_stay = (data) => {
    if (data === "hotel") {
      return capitalize(data);
    } else if (data === "own_or_relative_house") {
      return capitalize(place_of_stay_destination?.[1]?.name);
    }else{
      return "Others";
    }
  };

  return (
    <div>
      <Divider style={{ backgroundColor: "black" }} />
      {data.loading ? (
        <div className="h-50 text-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="mx-auto">
          <div div className="row mt-5">
            <div
              className="container text-center d-inline-block pass"
              style={{ border: "1px solid black" }}
            >
              <div className="row">
                <div
                  className="col-2 small-card"
                  style={{ backgroundColor: color[data?.reason?.code] }}
                >
                  {data?.reason?.code === "T" ||
                  data?.reason?.code === "B" ||
                  data?.reason?.code === "FAM" ? (
                    <div className="row" style={{ display: "ruby" }}>
                      {from_code?.split("").map((x, i) => (
                        <div key={i} style={{ margin: "auto", color: "white" }}>
                          {x}
                        </div>
                      ))}
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto", color: "white" }}>-</div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      {to_code?.split("").map((x, i) => (
                        <div key={i} style={{ margin: "auto", color: "white" }}>
                          {x}
                        </div>
                      ))}
                    </div>
                  ) : data?.reason?.code === "LR" ? (
                    <div className="row">
                      {"LOCAL".split("").map((x, i) => (
                        <div key={i} style={{ margin: "auto", color: "white" }}>
                          {x}
                        </div>
                      ))}
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto", color: "white" }}>-</div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      {to_code?.split("").map((x, i) => (
                        <div key={i} style={{ margin: "auto", color: "white" }}>
                          {x}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      {"COM".split("").map((x, i) => (
                        <div key={i} style={{ margin: "auto", color: "white" }}>
                          {x}
                        </div>
                      ))}
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto", color: "white" }}>-</div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      {data?.reason?.code?.split("").map((x, i) => (
                        <div key={i} style={{ margin: "auto", color: "white" }}>
                          {x}
                        </div>
                      ))}
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto", color: "white" }}>-</div>
                      <div style={{ margin: "auto" }}> </div>
                      <div style={{ margin: "auto" }}> </div>
                      {to_code?.split("").map((x, i) => (
                        <div key={i} style={{ margin: "auto", color: "white" }}>
                          {x}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-10 mt-3">
                  <div className="center">
                    <img src={logo} alt="logo" width={100} />
                    <br />
                    <span className="fw-bold fs-3">Tamil Nadu ePass</span>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <QRCode
                        id="QR"
                        value={data?.qr_data || " "}
                        level={"L"}
                        includeMargin={true}
                        renderAs={"svg"}
                        size={deviceType === "mobile" ? 100 : 200}
                      />
                    </div>
                    <div className="col-6 text-center vehicle-number">
                      <span>{data?.vehicle_number?.slice(0, -4)}</span>
                      <br />
                      <span>{data?.vehicle_number?.slice(-4)}</span>
                    </div>
                  </div>
                  <div style={{ gap: "10px", display: "grid" }}>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Validity
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size text-bold">
                        From{" "}
                        <span className="validity">
                          {moment(data?.valid_from).format("DD-MMM")}
                        </span>{" "}
                        until{" "}
                        <span className="validity">
                          {moment(data?.valid_till).format("DD-MMM")}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Pass Number
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {data?.pass_number}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Name
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {data?.name}
                      </div>
                    </div>
                    <div className="row">
                      {data?.applicant?.mobile ? (
                        <>
                          <div className="col-3 text-start fw-bold b-font-size">
                            Mobile Number
                          </div>
                          <div className="col-1 text-center b-font-size">:</div>
                          <div className="col-7 text-start o-font-size">
                            {data?.applicant?.mobile}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-3 text-start fw-bold b-font-size">
                            Email
                          </div>
                          <div className="col-1 text-center b-font-size">:</div>
                          <div className="col-7 text-start o-font-size">
                            {data?.applicant?.email}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Vehicle Type
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {data?.vehicle_type?.name}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Fuel Type
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {lodash.startCase(data?.vehicle_fuel_type)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Year of Manufacture
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {data?.vehicle_manufactured_year}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Total Travelers
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {data?.total_passengers}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Purpose of Visit
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {data?.reason?.name}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        From
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {address}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Issued By
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {data?.approved_by?.name} on{" "}
                        {moment(data?.approved_on).format("DD-MMM-YYYY")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Destination Type
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {render_place_of_stay(destination_type)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3 text-start fw-bold b-font-size">
                        Destination Address
                      </div>
                      <div className="col-1 text-center b-font-size">:</div>
                      <div className="col-7 text-start o-font-size">
                        {destination_name && (
                          <>
                            {capitalize(destination_name)}
                            <br />
                          </>
                        )}
                        {capitalize(destination_address)}
                      </div>
                    </div>

                  </div>
                  <div className={`${destination_name ? "":"mt-4"}`}>
                    <div className="fw-bold text-start">
                      Terms and Conditions
                    </div>
                    <div className="o-font-size">
                      {ReactHtmlParser(terms || " ")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "80%",
              marginBottom: "30px",
              marginTop: "30px",
            }}
          >
            <LoadingButton
              variant="contained"
              fullWidth
              color="primary"
              size="small"
              type="submit"
              loading={loading}
              style={{ width: "140px" }}
              onClick={savePDF}
            >
              Download <CloudDownloadIcon disabled={false} />
            </LoadingButton>
          </div>
        </div>
      )}
      <div
        id="pass"
        className="container text-center pass"
        style={{ border: "1px solid black", display: "none", mt: "1000px" }}
      >
        <div className="row" style={{ width: "210mm", height: "297mm" }}>
          <div
            className="col-2 small-card-download"
            style={{ backgroundColor: color[data?.reason?.code] }}
          >
            {data?.reason?.code === "T" ||
            data?.reason?.code === "B" ||
            data?.reason?.code === "FAM" ? (
              <div className="row" style={{ display: "ruby" }}>
                {from_code?.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto", color: "white" }}>-</div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                {to_code?.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
              </div>
            ) : data?.reason?.code === "LR" ? (
              <div className="row">
                {"LOCAL".split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto", color: "white" }}>-</div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                {to_code?.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
              </div>
            ) : (
              <div className="row">
                {"COM".split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto", color: "white" }}>-</div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                {data?.reason?.code?.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto", color: "white" }}>-</div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                {to_code?.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-10 mt-3">
            <div className="center">
              <img src={logo} alt="logo" width={100} />
              <br />
              <span className="fw-bold fs-3">Tamil Nadu ePass</span>
            </div>
            <div className="row">
              <div className="col-4">
                <QRCode
                  id="QR"
                  value={data?.qr_data || " "}
                  level={"L"}
                  includeMargin={true}
                  renderAs={"svg"}
                  size={200}
                />
              </div>
              <div className="col-6 text-center vehicle-number-download">
                <span>{data?.vehicle_number?.slice(0, -4)}</span>
                <br />
                <span>{data?.vehicle_number?.slice(-4)}</span>{" "}
              </div>
            </div>
            <div style={{ gap: "10px", display: "grid" }}>
              <div className="row">
                <div className="col-4 text-start fw-bold">Validity</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start text-bold">
                  From{" "}
                  <span style={{ fontSize: "25px", fontWeight: "bolder" }}>
                    {moment(data?.valid_from).format("DD-MMM")}
                  </span>{" "}
                  until{" "}
                  <span style={{ fontSize: "25px", fontWeight: "bolder" }}>
                    {moment(data?.valid_till).format("DD-MMM")}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Pass Number</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.pass_number}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Name</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.name}</div>
              </div>
              <div className="row">
                {data?.applicant?.mobile ? (
                  <>
                    <div className="col-4 text-start fw-bold">
                      Mobile Number
                    </div>
                    <div className="col-1 text-center">:</div>
                    <div className="col-7 text-start">
                      {data?.applicant?.mobile}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-4 text-start fw-bold">Email</div>
                    <div className="col-1 text-center">:</div>
                    <div className="col-7 text-start">
                      {data?.applicant?.email}
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Vehicle Type</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                  {data?.vehicle_type?.name}
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Fuel Type</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                  {lodash.startCase(data?.vehicle_fuel_type)}
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">
                  Year of Manufacture
                </div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                  {data?.vehicle_manufactured_year}
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Total Travelers</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.total_passengers}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Purpose of Visit</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.reason?.name}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">From</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{address}</div>
              </div>

              <div className="row">
                <div className="col-4 text-start fw-bold">Issued By</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                  {data?.approved_by?.name} on{" "}
                  {moment(data?.approved_on).format("DD-MMM-YYYY")}
                </div>
              </div>

              <div className="row">
                <div className="col-4 text-start fw-bold">Destination Type</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                {render_place_of_stay(destination_type)}
                </div>
              </div>

              <div className="row">
                <div className="col-4 text-start fw-bold">Destination Address</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                {destination_name && (
                          <>
                            {capitalize(destination_name)}
                            <br />
                          </>
                        )}
                        {capitalize(destination_address)}
                </div>
              </div>
            </div>
            <div className={`${destination_name ? "mt-1":"mt-5"}`}>
              <div className="fw-bold text-start">Terms and Conditions</div>
              {ReactHtmlParser(terms || " ")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pass;
