import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Col,
  Row,
  Modal,
  Radio,
  Tex,
  Divider,
} from "antd";
import { retrieveItem } from "@helpers/storage";
import moment from "moment";
import {
  dynamicClear,
  dynamicRequest,
  submit_application_query,
  useDynamicSelector,
} from "@services/redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import toast from "react-hot-toast";
import Pass from "@views/pages/pass";
import ReactHtmlParser from "html-react-parser";
import { place_of_stay_destination } from "@helpers/constants";
import AntdForm from "../ui/antd_form/antd_form";
import { showToast } from "@helpers/toast";


const { Option } = Select;
const ApplyEPass = () => {
  const { id } = useParams();
  const errors = useDynamicSelector("submit_application");
  const regex = /^[a-zA-Z0-9\s]*$/;
  const dispatch = useDispatch();
  let terms = retrieveItem("general_settings")?.pass_terms;
  const [model_open, set_model_open] = useState(false);
  const [confirmation_model_open, set_confirmation_model_open] =
    useState(false);
  const [validFromDate, setValidFromDate] = useState(null);
  const [data, setData] = useState({});
  const [placeOfStay, setPlaceOfStay] = useState(null);
  const [hotel_name_value, set_hotel_name_value] = useState(null);
  const [hotels_list, set_hotels_list] = useState([]);
  const [dropdown_open, set_dropdown_open] = useState(false);
  const [
    destination_address_text_box_show,
    set_destination_address_text_box_show,
  ] = useState(false);
  const [form] = Form.useForm();
  const countries = retrieveItem("countries");
  const vehicle_types = retrieveItem("vehicle_types");
  const purpose_of_visit = retrieveItem("reasons");
  const foreign = retrieveItem("foreign");
  const general_settings = retrieveItem("general_settings");
  const district = retrieveItem("district");

  const active_from = moment(new Date(general_settings?.active_from)).format(
    "YYYY-MM-DD"
  );
  const active_till = moment(new Date(general_settings?.active_till)).format(
    "YYYY-MM-DD"
  );
  const fuelTypes = [
    { id: "petrol", name: "Petrol" },
    { id: "diesel", name: "Diesel" },
    { id: "cng", name: "CNG" },
    { id: "electric", name: "Electric" },
    { id: "hybrid", name: "Hybrid" },
  ];
  const [states, set_states] = useState([]);
  const [districts, set_districts] = useState([]);
  const [place_of_stay, set_place_of_stay] = useState("");
  const [reason, set_reason] = useState(false);
  const [hotels, set_hotels] = useState([]);

  const destination_watch = Form.useWatch("destination_type", form);
  const hotel_name_watch = Form.useWatch("hotel_name", form);

  const {
    id: application_id,
    error,
    status,
    loading,
  } = useDynamicSelector("submit_application");

  const onchange_hotel_names = useCallback((string) => {
    if (!string) {
      set_hotels(hotels_list || []);
      return;
    }
  
    const searchTerm = string.toLowerCase();
  
    const filtered_hotels = hotels_list?.filter(
      (hotel) =>
        typeof hotel?.name === "string" &&
        hotel.name.toLowerCase().includes(searchTerm)
    );
  
    const sorted_hotels = filtered_hotels.sort((a, b) => {
      const isExactA = a.name.toLowerCase() === searchTerm ? -1 : 0;
      const isExactB = b.name.toLowerCase() === searchTerm ? -1 : 0;
      return isExactB - isExactA || a.name.localeCompare(b.name);
    });
  
    set_hotels(sorted_hotels || []);
  }, [hotels_list]);
  

  // useEffect(() => {
  //   const filtered_hotels = hotels_list?.filter(
  //     (hotel) =>
  //       typeof hotel?.name === "string" &&
  //       hotel.name.toLowerCase().includes(hotel_name_value?.toLowerCase() || "")
  //   );
  //   set_hotels(filtered_hotels || []);
  // }, [hotel_name_value, hotels_list]);

  useEffect(() => {
    const filtered_hotels = hotels_list
      ?.filter(
        (hotel) =>
          typeof hotel?.name === "string" &&
          hotel.name.toLowerCase().includes(hotel_name_value?.toLowerCase() || "")
      )
      .reduce((unique, hotel) => {
        if (!unique.some((h) => h.name === hotel.name)) {
          unique.push(hotel);
        }
        return unique;
      }, []);
  
    set_hotels(filtered_hotels || []);
  }, [hotel_name_value]);

  const get_hotels_list_data = () => {
    fetch("https://blr1.vultrobjects.com/epass/hotels_list.json")
      .then((response) => {
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        set_hotels_list(data.filter(
          (item) => district === item?.district
        ))
        set_hotels(data.filter(
          (item) => district === item?.district
        ))
      })
      .catch((error) => {
        // console.error("There was a problem with the fetch operation:", error);
      });
  };
  
  useEffect(() => {
    
    if (district) {
      get_hotels_list_data()
    }
  }, [ district]);

  useEffect(() => {
    if (data?.get_master_data) {
      const { get_master_data } = data;
      const {
        countries,
        general_settings,
        reasons,
        vehicle_types,
        feedback_types,
      } = get_master_data;
      storeItem("countries", countries);
      storeItem("general_settings", general_settings);
      storeItem("reasons", reasons);
      storeItem("vehicle_types", vehicle_types);
      storeItem("feedback_types", feedback_types);
    }
  }, [data]);

  const get_master_data = () => {
    fetch("https://blr1.vultrobjects.com/epass/master_data1.json")
      .then((response) => {
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        set_data(data.data);
        // Do something with the data
      })
      .catch((error) => {
        // console.error("There was a problem with the fetch operation:", error);
      });
  };

  useEffect(() => {
    if (foreign === "false") {
      form.setFieldsValue({ country_id: countries?.[0]?.id });
      let states = countries?.[0]?.states;
      set_states(states || []);
      form.setFieldsValue({ state_id: states?.[0]?.id });
      set_districts(states?.[0]?.districts || []);
    }
    if (!general_settings?.length) {
      get_master_data();
    }
  }, []);

  useEffect(() => {
    if (errors && errors?.error === "Not authorized") {
      showToast({ type: "error", message: "Not Authorized" });
      dispatch(dynamicClear("submit_application"));
      navigate("/");
      localStorage.clear();
    }
  }, [errors]);

  useEffect(() => {
    if (application_id) {
      // navigate(ROUTES.DASHBOARD);
      handle_confirmation_cancel();
      set_model_open(true);
    } else if (status === "failure") {
      toast.error("Something went wrong, Please try again!");
    }
  }, [application_id]);

  const onChange = (val, values) => {
    if (val.country_id) {
      let states = countries.find((x) => x.id === val.country_id)?.states;
      set_states(states || []);
      set_districts(states?.[0]?.districts || []);
      form.setFieldsValue({
        state_id: states?.[0]?.id || "",
        district_id: "",
      });
    }
    if (val.state_id) {
      let districts = states.find((x) => x.id === val.state_id)?.districts;
      set_districts(districts || []);
      form.setFieldsValue({
        district_id: "",
      });
    }
    if (val.know_the_place_of_stay) {
      set_place_of_stay(val.know_the_place_of_stay);
    }

    if (val.reason_id) {
      let reason = purpose_of_visit.find(
        (x) =>
          x.id === val.reason_id &&
          (x.name === "Agri Commodity " || x.name === "Essential Commodity ")
      );
      if (reason?.id) {
        set_reason(true);
        form.setFieldsValue({
          valid_till: moment(active_till).subtract(1, "day"),
        });
      } else {
        set_reason(false);
        form.setFieldsValue({ valid_till: null });
      }
    }
  };

  const onFinish = (values) => {
    const { file_upload } = values;
    if (
      destination_watch != "own_or_relative_house" &&
      !file_upload?.[0]?.url
    ) {
      showToast({
        type: "error",
        message: "Invalid File Format",
      });
    } else {
      setData(values);
      set_confirmation_model_open(true);
    }
  };

  const submit_application = () => {
    debugger;
    let values = data;
    const { file_upload, hotel_name, hotel_address, ...rest } = values;
    const trimmedValue = values?.vehicle_number?.trim()?.replace(/ /g, "");
    let submit_application_key = [{ key: "submit_application", loading: true }];
    let submit_application_variables = {
      data: {
        ...rest,
        vehicle_number: trimmedValue,
        destination_id: id,
        pincode: String(values.pincode),
        vehicle_manufactured_year: String(values.vehicle_manufactured_year),
        know_the_place_of_stay: true,
        destination_name:
          hotel_name_watch === "others"
            ? values?.destination_name
            : hotel_name_value,
        destination_address: hotel_address ?? values?.destination_address,
        hotel_id: values?.hotel_name === "others" ? null :values?.hotel_name,
        destination_type:
          values?.hotel_name === "others" ? "other" : values?.destination_type,
        destination_attachment_url: file_upload?.[0]?.url,
      },
    };
    dispatch(
      dynamicRequest(
        submit_application_key,
        submit_application_query,
        submit_application_variables,
        "M"
      )
    );
  };
  const disabledDates = (current) => {
    return (
      current &&
      (current <
        (moment(active_from).subtract(1, "day").endOf("day") &&
          moment().subtract(1, "day").endOf("day")) ||
        current > moment(active_till).subtract(1, "day").endOf("day"))
    );
  };
  const disabledDatesexit = (current) => {
    return (
      // Disable dates before active_from - 1 day
      current < moment(active_from).subtract(1, "day").endOf("day") ||
      // Disable dates after active_till
      current > moment(active_till).subtract(1, "day").endOf("day") ||
      // Disable dates before validFromDate, if it is defined
      (validFromDate && current < validFromDate.startOf("day"))
    );
  };

  const handleValidFromDateChange = (date) => {
    setValidFromDate(date);
  };

  const handle_cancel = () => {
    set_model_open(false);
    navigate(ROUTES.DASHBOARD);
  };
  const handle_confirmation_cancel = () => {
    set_confirmation_model_open(false);
  };
  const options = [
    {
      label: "தங்கும் இடம் எங்களுக்கு தெரியும் / I know my place of stay",
      value: "yes",
    },
    {
      label: "தங்கும் இடம் இன்னும் தெரியாது  / I don't know my place of stay",
      value: "no",
    },
  ];

  useEffect(() => {
    let hotel = hotels?.find((item, index) => {
      return item?.id === hotel_name_watch;
    });

    if (hotel?.address) {
      form.setFieldsValue({ hotel_address: hotel?.address });
      set_destination_address_text_box_show(false);
    } else {
      set_destination_address_text_box_show(true);
    }
  }, [hotel_name_watch]);

  const on_change_destination_type = () => {
    form.setFieldsValue({
      hotel_name: null,
      hotel_address: null,
      destination_name: null,
    });
  };

  // const on_dropdown_visible_change = (is_open)=>{
  //   if(is_open){
  //     set_hotel_name_value(null)
  //   }
  // }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            textAlign: "center",
            fontSize: "23px",
            backgroundColor: "ButtonHighlight",
            width: "300px",
            padding: "5px",
          }}
        >
          TN ePass Application
        </div>
      </div>

      <br />
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onValuesChange={onChange}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="name"
              label="விண்ணப்பதாரர் பெயர் / Applicant Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your applicant name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            md={12}
            lg={8}
            style={{ display: "flex", alignItems: "end" }}
          >
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="reason_id"
              label="வருகையின் காரணம் / Purpose of Visit"
              rules={[
                { required: true, message: "Please enter purpose of visit!" },
              ]}
            >
              <Select size="large">
                {purpose_of_visit?.map((purpose) => (
                  <Option key={purpose.id} value={purpose.id}>
                    {purpose.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              name="vehicle_number"
              label="வாகன பதிவு எண் / Vehicle Registration Number"
              rules={[
                {
                  required: true,
                  message: "Please enter your vehicle registration number",
                },
                {
                  pattern: regex,
                  message: "Only numbers, alphabets, and spaces are allowed!",
                },
              ]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input style={{ textTransform: "uppercase" }} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="total_passengers"
              label="மொத்த பயணிகளின் எண்ணிக்கை / Number of Passengers"
              rules={[
                {
                  required: true,
                  message: "Please enter number of passengers!",
                },
                {
                  type: "number",
                  max: 60,
                  message: "Number must be less than or equal to 60!",
                },
              ]}
            >
              <InputNumber
                size="middle"
                changeOnWheel={false}
                controls={false}
                min={1}
                max={60}
                maxLength={2}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="vehicle_manufactured_year"
              label="வாகன உற்பத்தி வருடம் / Year of Manufacture"
              rules={[
                {
                  required: true,
                  message: "Please enter year of manufacture!",
                },
              ]}
            >
              <InputNumber changeOnWheel={false} controls={false} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="vehicle_type_id"
              label="வாகன வகை / Vehicle Type"
              rules={[
                { required: true, message: "Please enter your vehicle type" },
              ]}
            >
              <Select size="large">
                {vehicle_types?.map((type) => (
                  <Option key={type.id} value={type.id}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="vehicle_fuel_type"
              label="எரிபொருள் வகை / Fuel Type"
              rules={[{ required: true, message: "Please enter fuel type!" }]}
            >
              <Select size="large">
                {fuelTypes?.map((fuelType) => (
                  <Option key={fuelType.id} value={fuelType.id}>
                    {fuelType.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="valid_from"
              label="உள் நுழையும் நாள் / Date of Entry"
              rules={[
                { required: true, message: "Please enter your date of entry!" },
              ]}
            >
              <DatePicker
                onChange={handleValidFromDateChange}
                disabledDate={disabledDates}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="valid_till"
              label="வெளியேறும் நாள் / Date of Exit"
              rules={[
                {
                  required: true,
                  message: "Please enter your date of exit!",
                  // validator: (_, value) => {
                  //   if (!value || value.isAfter(validFromDate)) {
                  //     return Promise.resolve();
                  //   }
                  //   return Promise.reject(
                  //     new Error("Exit date must be after entry date!")
                  //   );
                  // },
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const validFrom = getFieldValue("valid_from");

                    if (
                      value &&
                      validFrom &&
                      value.valueOf() <= validFrom.valueOf()
                    ) {
                      if (value.isSame(validFrom, "day")) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Valid Till should not be earlier than Valid From"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                disabled={reason}
                disabledDate={disabledDatesexit}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="country_id"
              label="நாடு / Country"
              rules={[{ required: true, message: "Please enter country!" }]}
            >
              <Select
                disabled={foreign !== "true"}
                size="large"
                defaultActiveFirstOption={true}
              >
                {foreign === "true"
                  ? countries?.slice(1)?.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.name}
                      </Option>
                    ))
                  : countries?.map((country) => (
                      <Option key={country.id} value={country.id}>
                        {country.name}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>

          {states?.length ? (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="state_id"
                label="மாநிலம் / State"
                rules={[
                  {
                    required: foreign === "true" ? false : true,
                    message: "Please enter state!",
                  },
                ]}
              >
                <Select
                  disabled={foreign === "true"}
                  size="large"
                  defaultActiveFirstOption={true}
                >
                  {states?.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          {districts?.length ? (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="district_id"
                label="மாவட்டம் / District"
                rules={[
                  {
                    required: foreign === "true" ? false : true,
                    message: "Please enter district!",
                  },
                ]}
              >
                <Select
                  disabled={foreign === "true"}
                  size="large"
                  defaultActiveFirstOption={true}
                >
                  {districts?.map((district) => (
                    <Option key={district.id} value={district.id}>
                      {district.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="from_address_1"
              label="முகவரி 1 / Address Line 1"
              rules={[
                { required: true, message: "Please enter address line 1!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="from_address_2"
              label="முகவரி 2 / Address Line 2"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8}>
            {foreign === "true" ? (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="pincode"
                label="அஞ்சல் குறியீடு / Zipcode"
                rules={[{ required: true, message: "Please enter zipcode!" }]}
              >
                <InputNumber changeOnWheel={false} controls={false} />
              </Form.Item>
            ) : (
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="pincode"
                label="அஞ்சல் குறியீடு / Pincode"
                rules={[
                  { required: true, message: "Please enter pincode!" },
                  {
                    pattern: /^[0-9]{6}$/, // Regular expression for 6-digit pincode
                    message: "Please enter a valid 6-digit pincode!",
                  },
                ]}
              >
                <InputNumber changeOnWheel={false} controls={false} />
              </Form.Item>
            )}
          </Col>

          {/* <Col xs={24} md={24} lg={24}>
            <Form.Item
              rules={[{ required: true, message: "Please choose Stay" }]}
              labelCol={{ span: 24 }}
              name="know_the_place_of_stay"
              wrapperCol={{ span: 24 }}
            >
              <Radio.Group options={options} />
            </Form.Item>
          </Col> */}
          {/* {place_of_stay === "yes" ? (
            <Col xs={24} md={24} lg={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="destination_address"
                label="தங்கும் இடத்தின் முகவரி  / Address of Stay"
                rules={[
                  { required: true, message: "Please enter address of stay!" },
                ]}
              >
                <Input changeOnWheel={false} controls={false} />
              </Form.Item>
            </Col>
          ) : (
            ""
          )} */}

          <Col xs={24} md={18} lg={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              name="destination_type"
              label="தங்கும் இடம் / Place of Stay"
              rules={[
                {
                  required: true,
                  message: "Please select place of stay!",
                },
              ]}
            >
              <Select
                allowClear
                onChange={on_change_destination_type}
                size="large"
                defaultActiveFirstOption={true}
                popupMatchSelectWidth={false}
              >
                {place_of_stay_destination?.map((district) => (
                  <Option key={district.id} value={district.id}>
                    {district.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {destination_watch != "own_or_relative_house" &&
            destination_watch && (
              <Col xs={24} md={18} lg={8}>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  name="hotel_name"
                  label="விடுதியின் பெயர் / Name of the Hotel"
                  rules={[
                    {
                      required: true,
                      message: "Please select name of the hotel!",
                    },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    allowClear
                    placeholder="Type atleast three letters to search."
                    optionFilterProp="children"
                    defaultActiveFirstOption={true}
                    popupMatchSelectWidth={true}
                    onChange={(data, record) => {
                      set_hotel_name_value(record?.children);
                    }}
                    onSearch={onchange_hotel_names}
                    filterOption={(input, option) => {
                      if (option?.key === "others") return true;
                      return option?.children
                        ?.toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    // onDropdownVisibleChange={on_dropdown_visible_change}
                  >
                    {hotels
                      .slice(0, 10)
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((district) => (
                        <Option key={district.id} value={`${district?.id}`}>
                          {district.name}
                        </Option>
                      ))}

                    <Option key="others" value="others">
                      Others
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

          {destination_watch != "own_or_relative_house" &&
            hotel_name_watch != "others" &&
            !destination_address_text_box_show &&
            destination_watch && (
              <Col xs={24} md={18} lg={8}>
                <Form.Item
                  wrapperCol={{ span: 24 }}
                  labelCol={{ span: 24 }}
                  name="hotel_address"
                  label="சேருமிடம் முகவரி / Destination Address"
                  rules={[
                    {
                      required: true,
                      message: "Please select place of stay!",
                    },
                  ]}
                >
                  <Select
                    disabled
                    size="large"
                    showSearch
                    placeholder="Search for a place"
                    optionFilterProp="children"
                    defaultActiveFirstOption={true}
                    popupMatchSelectWidth={false}
                    onSearch={(value) => {
                      // Add search logic here if needed
                    }}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {hotels?.slice(0, 10).map((district) => (
                      <Option key={district.id} value={district.id}>
                        {district.address}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

          {/* destination_watch != "own_or_relative_house" || */}
          {hotel_name_watch &&
            hotel_name_watch === "others" &&
            destination_watch && (
              <Col xs={24} md={12} lg={8}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name="destination_name"
                  label="சேருமிடம் பெயர் / Destination Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter destination name!",
                    },
                  ]}
                >
                  <Input maxLength={45} />
                </Form.Item>
              </Col>
            )}

          {destination_address_text_box_show && destination_watch && (
            <Col xs={24} md={12} lg={8}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="சேருமிடம் முகவரி / Destination Address"
                name="destination_address"
                rules={[
                  {
                    required: true,
                    message: "Please enter destination address!",
                  },
                ]}
              >
                <Input maxLength={80} />
              </Form.Item>
            </Col>
          )}

          {/* destination_watch != "own_or_relative_house" || */}
          {destination_watch &&
            destination_watch != "own_or_relative_house" &&
            destination_watch && (
              <Col xs={24} md={12} lg={8}>
                <AntdForm.File
                  label={"முன்பதிவு ரசீது / Booking Receipt "}
                  field={"file_upload"}
                  rules={[{ required: true, message: "File is required!" }]}
                  allowFileFormats={["png", "jpeg"]}
                />
              </Col>
            )}
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: "#346732" }}
              loading={loading}
            >
              சமர்ப்பிக்க / Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        title={"Download Your Pass"}
        open={model_open}
        width={1000}
        // onOk={handle_ok}
        onCancel={handle_cancel}
        footer={null}
      >
        <Pass id={application_id} />
      </Modal>
      <Modal
        title={"Confirmation"}
        open={confirmation_model_open}
        width={1000}
        // onOk={handle_ok}
        onCancel={handle_confirmation_cancel}
        footer={null}
      >
        <Divider />
        <div className="text-start text-red">
          கீழே உள்ள நிபந்தனைகளையும், பிற சட்டங்கள், விதிகள் மற்றும்
          விதிமுறைகளையும் பின்பற்றுவதாக ஒப்புதல் அளிக்கிறேன். ஏதாவது மீறல்களில்
          ஈடுபட்டால், நான் சட்டப்படி தண்டிக்கப்படுவேன் என்று உணர்ந்துள்ளேன்.
          <br />
          By clicking on confirm, I hereby agree to abide by the conditions
          given below and any other laws, rule and regulations applicable. I
          will be liable to punishment according to law in case of any
          violations.
          <br />
          <br />
        </div>
        {ReactHtmlParser(terms || " ")}
        <Divider />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            style={{ marginRight: "10px" }}
            onClick={handle_confirmation_cancel}
          >
            Cancel
          </Button>
          <Button
            loading={loading}
            variant="contained"
            onClick={submit_application}
            type="primary"
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ApplyEPass;
