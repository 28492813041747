import { retrieveItem, storeItem } from "@helpers/storage";
import { Router } from "@views/routes/Router";
import { useEffect, useState } from "react";

function App() {
  const [data, set_data] = useState({});
  const [hotels_list, set_hotels_list] = useState(null);
  console.log("hotels_list: ", hotels_list);
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );

        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }

        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }

      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message === "Script error." ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );

        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );

        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }

        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
    get_master_data();
    get_hotels_list_data()
  }, []);
  useEffect(() => {
    if (data?.get_master_data) {
      const { get_master_data } = data;
      const {
        countries,
        general_settings,
        reasons,
        vehicle_types,
        feedback_types,
      } = get_master_data;
      storeItem("countries", countries);
      storeItem("general_settings", general_settings);
      storeItem("reasons", reasons);
      storeItem("vehicle_types", vehicle_types);
      storeItem("feedback_types", feedback_types);
    }
  }, [data]);

  useEffect(()=>{
    if(hotels_list){
      storeItem("hotels_list", hotels_list);
    }
  },[hotels_list]);

  const get_master_data = () => {
    fetch("https://blr1.vultrobjects.com/epass/master_data1.json")
      .then((response) => {
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        set_data(data.data);
        // Do something with the data
      })
      .catch((error) => {
        // console.error("There was a problem with the fetch operation:", error);
      });
  };

  const get_hotels_list_data = () => {
    fetch("https://blr1.vultrobjects.com/epass/hotels_list.json")
      .then((response) => {
        if (!response) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("data_hotel: ", data);
        set_hotels_list(data);
      })
      .catch((error) => {
        // console.error("There was a problem with the fetch operation:", error);
      });
  };

  return <Router />;
}
export default App;
