import React, { useEffect, useState } from "react";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import Header from "./header";
import Card from "@views/components/card/card";
import kodaikanal from "@assets/images/Kodaikanal.jpg";
import nilgiris from "@assets/images/nilgiiris.png";
import local from "@assets/images/LocalPass.png";
import previous from "@assets/images/PreviousPass.png";
import { Modal } from "antd";
import LocalBodyResidency from "./local_body_residancy";
import { destination } from "@helpers/constants";
import { retrieveItem, storeItem } from "@helpers/storage";
import { dynamicClear } from "@services/redux";
import { useDispatch } from "react-redux";
import Feedback from "./feedback_model";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [local_bosy_model_open, set_local_body_model_open] = useState(false);
  const [feedback_model_open, set_feedback_model_open] = useState(false);
  const foreign = retrieveItem("foreign");
  const hotel = retrieveItem("hotel_list");
  console.log("hotel",hotel);
  
  useEffect(() => {
    dispatch(dynamicClear("get_otp"));
    dispatch(dynamicClear("login_user"));
    dispatch(dynamicClear("submit_application"));
  }, []);
  const handle_nilgiries = () => {
    navigate(ROUTES.APPLY_EPASS + "/" + destination.nilgiris);
    storeItem("district", "Nilgiris");
  };

  const handle_kodaikkanal = () => {
    navigate(ROUTES.APPLY_EPASS + "/" + destination.kodaikanal);
    storeItem("district", "Dindigul");
  };

  const handle_local_body = () => {
    set_local_body_model_open(true);
  };
  const handle_previous = () => {
    navigate(ROUTES.PREVIOUS_PASSES);
  };

  const handle_cancel = () => {
    set_local_body_model_open(false);
    dispatch(dynamicClear("submit_local_resident_application"));
  };

  const handle_cancel_feedback = () => {
    set_feedback_model_open(false);
    dispatch(dynamicClear("submit_feedback"));
  };

  const handle_feedback = () => {
    set_feedback_model_open(true);
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <div className="dashboard-card">
        <div className="text-center mb-2 mt-5">
          நீங்கள் செல்ல விரும்பும் இடம் / Choose your destination
        </div>
        <div className="row grid-container">
          <Card
            imageUrl={nilgiris}
            text="நீலகிரி / Nilgiris"
            color="#604432d6"
            onClick={handle_nilgiries}
          />
          <Card
            imageUrl={kodaikanal}
            text="கொடைக்கானல் / Kodaikanal"
            color="#3c6e36d6"
            onClick={handle_kodaikkanal}
          />
        </div>
        <div className="row grid-container my-5 pt-5">
          {foreign === "false" ? (
            <Card
              imageUrl={local}
              text="உள்ளூர் பாஸ் /
            Localite Pass"
              color="#363c6ed6"
              onClick={handle_local_body}
            />
          ) : (
            ""
          )}
          <Card
            imageUrl={previous}
            text="முந்தைய பாஸ்கள் / Previous Passes"
            color="#9e288bd6"
            onClick={handle_previous}
          />
        </div>
      </div>
      <div className="feedback-text text-center mb-2" onClick={handle_feedback}>
        கருத்து சமர்ப்பிக்க / Share Feedback
      </div>
      <Modal
        title={<span style={{ fontSize: "22px" }}>Get your Local ePass</span>}
        open={local_bosy_model_open}
        width={700}
        // onOk={handle_ok}
        onCancel={handle_cancel}
        footer={null}
      >
        <LocalBodyResidency handle_primary_cancel={handle_cancel} />
      </Modal>
      <Modal
        title={<span style={{ fontSize: "22px" }}>Submit your Feedback</span>}
        open={feedback_model_open}
        width={500}
        // onOk={handle_ok}
        onCancel={handle_cancel_feedback}
        footer={null}
      >
        <Feedback handle_primary_cancel={handle_cancel_feedback} />
      </Modal>
    </div>
  );
};

export default Dashboard;
